const customSoftware = [
  {
    title: "How to Know if Your Business Needs Custom Software Solutions",
    date: '08 December 2024',
    url: 'customblog2cover.jpg',
    type: 'Custom Software Solutions',
    route: '/blogs/custom-software-solutions/how-to-know-if-your-business-needs-custom-software-solutions',
  },
  {
    title: "Why Custom Software Solutions are Ideal for Growing Businesses",
    date: '21 November 2024',
    url: 'customblog1cover.jpg',
    type: 'Custom Software Solutions',
    route: '/blogs/custom-software-solutions/why-custom-software-solutions-are-ideal-for-growing-businesses',
  },
];
const mobileAppDevelopment = [
  {
    title: "The Role of IoT in Mobile App Development: Connecting Devices and People",
    date: '25 October 2024',
    url: 'mobileblog4cover.jpg',
    type: 'Mobile App Development',
    route: '/blogs/mobile-app-development/role-of-iot-in-mobile-app-development',
  },
  {
    title: "The Role of Artificial Intelligence in Mobile Apps",
    date: '19 October 2024',
    url: 'mobileblog3cover.jpg',
    type: 'Mobile App Development',
    route: '/blogs/mobile-app-development/role-of-artificial-intelligence-in-mobile-apps',
  },
  {
    title: "Mobile App Development Services We Offer: From Concept to Launch",
    date: '12 October 2024',
    url: 'mobileblog2cover.jpg',
    type: 'Mobile App Development',
    route: '/blogs/mobile-app-development/mobile-app-development-services-we-offer-from-concept-to-launch',
  },
  {
    title: "Why does Your Business Needs a Custom Mobile App",
    date: '08 October 2024',
    url: 'mobileblog1cover.jpg',
    type: 'Mobile App Development',
    route: '/blogs/mobile-app-development/unlocking-success-why-does-your-business-needs-a-custom-mobile-app',
  },
];
const dataAnalytics = [
  {
    title: " The Future of Big Data Analytics: What’s on the Horizon?",
    date: '28 September 2024',
    url: 'datablog7cover.jpg',
    type: 'Data Analytics',
    route: '/blogs/data-analytics/the-future-of-big-data-analytics'
  },
  {
    title: "The Impact of Big Data on Marketing Strategies",
    date: '16 September 2024',
    url: 'datablog6cover.jpg',
    type: 'Data Analytics',
    route: '/blogs/data-analytics/impact-of-big-data-on-marketing-strategies'
  },
  {
    title: "Big Data in Healthcare: A Game Changer for Patient Care and Operational Efficiency",
    date: '03 September 2024',
    url: 'datablog5cover.jpg',
    type: 'Data Analytics',
    route: '/blogs/data-analytics/big-data-in-healthcare-a-game-changer-for-patient-care-and-operational-efficiency'
  },
  {
    title: "Machine Learning Techniques in Big Data Analytics",
    date: "19 August 2024",
    url: "datablog4cover.jpg",
    type: "Data Analytics",
    route:
      "/blogs/data-analytics/machine-learning-techniques-in-big-data-analytics",
  },
  {
    title:
      "Understanding the 5 Vs of Big Data: A Casual Dive into Data Dynamics",
    date: "12 August 2024",
    url: "datablog3cover.jpg",
    type: "Data Analytics",
    route: "/blogs/data-analytics/understanding-5-vs-of-big-data",
  },
  {
    title:
      " Comparative Analysis of Big Data Tools: A Deep Dive into the Heavy Hitters",
    date: "06 August 2024",
    url: "datablog2cover.jpg",
    type: "Data Analytics",
    route: "/blogs/data-analytics/comparative-analysis-of-big-data-tools",
  },
  {
    title: "The Role of Big Data Analytics in Business Intelligence",
    date: "03 August 2024",
    url: "datablog1cover.jpg",
    type: "Data Analytics",
    route:
      "/blogs/data-analytics/the-role-of-big-data-analytics-in-business-intelligence",
  },
];
const ai = [
  {
    title: "Impact of AI transformation on industries",
    date: "30 July 2024",
    url: "aiblog6cover.jpg",
    type: "Artificial Intelligence",
    route:
      "/blogs/artificial-intelligence/impact-of-ai-transformation-on-industries",
  },
  {
    title: "Ethical Considerations of AI",
    date: "27 July 2024",
    url: "aiblog5cover.jpg",
    type: "Artificial Intelligence",
    route: "/blogs/artificial-intelligence/ethical-considerations-of-ai",
  },
  {
    title: "Elevate Your Customer Experience with AI at Mkaits",
    date: "26 July 2024",
    url: "aiblog4cover.jpg",
    type: "Artificial Intelligence",
    route:
      "/blogs/artificial-intelligence/elevate-your-customer-experience-with-ai-at-mkaits",
  },
  {
    title: "Machine Learning vs. Deep Learning: What's the Difference?",
    date: "24 July 2024",
    url: "aiblog3cover.jpg",
    type: "Artificial Intelligence",
    route: "/blogs/artificial-intelligence/machine-learning-vs-deep-learning",
  },
  {
    title: "How to Begin Learning AI Skills?",
    date: "23 July 2024",
    url: "aiblog2cover.jpg",
    type: "Artificial Intelligence",
    route: "/blogs/artificial-intelligence/how-to-begin-learning-ai-skills",
  },
  {
    title: "What is coming in the world of AI in 2024?",
    date: "17 July 2024",
    url: "aiblog1cover.jpg",
    type: "Artificial Intelligence",
    route:
      "/blogs/artificial-intelligence/what-is-coming-in-the-world-of-ai-in-2024",
  },
];
const blockchain = [
  {
    title:
      "Exploring the World of Dapps: The Future of Decentralized Applications",
    date: "13 July 2024",
    url: "blog9cover.jpg",
    type: "Blockchain",
    route:
      "/blogs/blockchain/exploring-the-world-of-dapps-the-future-of-decentralized-applications",
  },
  {
    title: "DeFi: Reshaping the Financial Landscape",
    date: "09 July 2024",
    url: "blog8cover.jpg",
    type: "Blockchain",
    route: "/blogs/blockchain/defi-reshaping-the-financial-landscape",
  },
  {
    title: "The Transformative Potential of Decentralized Exchanges (DEX) ",
    date: "30 June 2024",
    url: "blog7cover.jpg",
    type: "Blockchain",
    route:
      "/blogs/blockchain/transformative-potential-of-decentralized-exchanges",
  },
  {
    title: "Decentralized Autonomous Organization (DAO)",
    date: "26 June 2024",
    url: "blog6cover.jpg",
    type: "Blockchain",
    route: "/blogs/blockchain/decentralized-autonomous-organization",
  },
];

export { dataAnalytics, ai, blockchain, mobileAppDevelopment, customSoftware };
